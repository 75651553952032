/* Album.css */
.page {
    position: relative;
    width: 10px;
    height: 100%;
    background-color: #ffeaf6;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transform-style: preserve-3d;
    perspective: 1000px;
    padding: 20px;
  }
  
  .page-cover {
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #f7b0d0, #ffeaf6); /* Gradien warna romantis */
    border-radius: 10px;
    transform: rotateY(0deg);
    transition: transform 0.6s;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 60px 30px; /* Mengurangi padding agar tidak terlalu besar */
    box-sizing: border-box;
  }
  
  .page-cover::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.2));
    border-radius: 15px;
    z-index: -1;
  }
  
  .page-cover h1 {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    color: #6f0016;
    margin: 0;
    position: relative;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  
  
  .page-cover p {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem; /* Mengurangi ukuran font */
    color: #5b3a5b;
    margin-top: 10px;
    font-style: italic;
  }
  
  .page-cover .cover-content {
    padding: 40px;
    border: 3px solid #e8a3c6; /* Border lembut */
    border-radius: 10px;
    background: #fcc6d1cc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1;
  }
  
  .page:hover .page-cover {
    transform: rotateY(-10deg);
  }
  
  .page-flip-container {

    justify-content: center;
    align-items: center;
    perspective: 1000px;
    margin-top: -60px; /*Buat ngatur jarak bukunya dari component sebelumnya*/
  }
  
  .page-flip {
    width: 300px; /* Ukuran default untuk desktop */
    height: 500px;
    margin: 20px; /* Memberikan margin lebih */
    border-radius: 15px;
  }

  .page-number {
    font-family: 'Poppins', sans-serif; /* Font yang lebih lembut dan mudah dibaca */
    opacity: 0.9; /* Menurunkan opasitas agar lebih lembut */
    transition: opacity 0.3s ease;
    font-size: 10px;
  }
  
  .page-number:hover {
    opacity: 1; /* Meningkatkan opasitas saat di-hover */
  }
  
  .photo {
    width: auto;
    height: 100%;
    max-height: 80%; /* Mengatur agar gambar tidak melebihi tinggi container */
    object-fit: cover; /* Menghindari stretch, gambar tetap dalam rasio aslinya */
    aspect-ratio: 4/3; /* Menetapkan rasio 4:3 */
    padding: 4px;
  }
  
  
  /* CSS untuk Mobile */
  @media (max-width: 768px) {
    .page-flip {
      width: 90vw; /* Menggunakan 90% dari lebar viewport di mobile */
      height: auto; /* Menyesuaikan tinggi secara proporsional */
      margin: 0 50px; /* Memberikan margin kecil pada sisi kiri-kanan */
    }
  
    .page-cover h1 {
      font-size: 2rem; /* Ukuran font lebih kecil di mobile */
    }
  
    .page-cover p {
      font-size: 0.9rem; /* Ukuran font deskripsi lebih kecil */
    }
  
    .page {
      padding: 25px; /* Padding lebih kecil untuk mobile */
    }
    .page-flip-container{
        
        justify-content: center;
        align-items: center;
        padding-right: 50px;
        padding-left: 50px;
    }


  }
  